.fr-card:before {
    content: none;
}
.fr-card:hover, .fr-card:active {
    background-color: unset;
}
.fullSizeButton {
    width: 100%;
    text-align: center;
}

.exportRGASection .fullSizeButton {
    min-width: 7.7rem;
}

.fullSizeButton_administration {
    font-size: 0.9rem;
    min-width: 15rem;
    margin: 1rem;
}

.surcharge-href-dsfr-menu-administration{
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}

.surcharge-href-dsfr-menu-administration [href]{
    background-image: none;
}

.testTag {
    position: absolute;
    right: 0;
}

.testTag p {
    background-color: red;
    color: white;
    font-weight: bold;
}

.testTagDemande {
    position: absolute;
    top: 0;
    right: 0;
}

.testTagDemande p {
    background-color: red;
    color: white;
    font-weight: bold;
}

.testTagDemandeContainer {
    position: relative;

}
