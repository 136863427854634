.captcha-rga {
    border: 0.2rem solid var(--background-contrast-grey);
    display: inline-block;
    border-radius: 0.5rem 0.5rem 0 0;
    width: calc(215px + 10rem)
}


.catpcha-rga__partie-haute {
    display: inline-flex;
    width: 100%;
}

.catpcha-rga__partie-haute__boutons {
    width: 100%;
    text-align: right;
}

@media (max-width: 400px){
    .captcha-rga {
        width: calc(215px + 6em)
    }
}