.mainContainerParametres {
    box-shadow: 0 0 5px lightgrey ;
    padding : 1.4rem;
    margin-bottom: 2rem;

}

.mainContainerParametres .rightSide {
    text-align: end;
    padding: 0;
}

.mainContainerParametres .centerText {
    text-align: center;
}

.mainContainerParametres .headerValeur {
    width: 20rem;
}

.mainContainerParametres thead{
    background-color: transparent;
}

.mainContainerParametres tbody{
    background-color: transparent;

}


.mainContainerParametres .valeurCell span {
    margin : 0 10px;
    color : var(--text-title-blue-france)
}

.mainContainerParametres .libelleCell {
    display: flex;
    font-weight: bolder;

}

.mainContainerParametres .loader-container {
    position: relative;
    overflow: hidden;
}

.mainContainerParametres .loadingTd {
    padding: 0;
    height: 15vh
}

.modaleParametres .modaleContent{
    border: 1px solid var(--background-action-high-blue-france);
    margin : 0;
    padding : 2rem
}

.modaleParametres .modaleContent .paragrapheHaut {
    margin-bottom: 5rem;
}

.modaleParametres .modaleContent input {
    background-color: transparent;

}