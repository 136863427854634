.fa-caracteristique-titre {
    margin-bottom: 0.5rem;
}

.fa-caracteristique-ligne {
    margin-bottom: 1.5rem;
}

.fa-caracteristique-ligne .fr-label + .fr-input {
    margin-top: 0;
}


.fa-caracteristique-ligne > div {
    padding-right: 0.5rem;
}

.fa-caracteristique-ligne > div:last-child {
    padding-right: 0;
}

.fa-caracteristique-l1 > div:nth-child(1), .fa-caracteristique-l1 > div:nth-child(2), .fa-caracteristique-l1 > div:nth-child(3) {
    width: 27%;
    max-width: 27%;
    flex: 0 0 27%;
}

.fa-caracteristique-l1 > div:nth-child(4) {
    width: 19%;
    max-width: 19%;
    flex: 0 0 19%;
}

.fa-caracteristique-longueur__padding-pdf{
    padding-left: 15px;
}