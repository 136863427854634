.etape__stepper {
    position: relative;
    min-height: 1em;
    width: 250px;
}

.titre__stepper {
    position: absolute;
    line-height: 1.5em;
    font-weight: bold;
}

.etape__stepper + .etape__stepper {
    margin-top: 3.5em
}

.etape__stepper > div:first-child {
    position: static;
    height: 0;
}

.etape__stepper > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
}

.cercle__stepper {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
    z-index: 2;
    left: 5px
}

.trait__stepper{
    content: ' ';
    position: absolute;
    display: block;
    top: -37px;
    bottom: 1px;
    left: 14px;
    height: 20px;
    border-right : 3px dotted;
    transform: scale(1, 2);
    transform-origin: 95% 46%;
}

.etape-en-cours__stepper .trait__stepper{
    top: -30px;
}

.etape__stepper:last-child .cercle__stepper:after {
    display: none
}

.etape__stepper.couleur-active__stepper {
    color: var(--background-action-high-blue-france);
}

.etape__stepper.couleur-active__stepper .cercle__stepper {
    background-color: var(--background-action-high-blue-france);
}

.etape-fini__stepper{
    color: #cacadf;
}

.etape-a-faire__stepper .cercle__stepper{
    background: #cecece !important;
}

.etape-en-cours__stepper .cercle__stepper{
    background: var(--background-action-high-blue-france) !important;
}

.etape-en-cours__stepper .titre__stepper{
    font-size: x-large;
    bottom: -0.75rem;
}

.etape-en-cours__stepper {
    color : var(--background-action-high-blue-france) !important;
}

.etape-a-faire__stepper {
    color : #cecece !important;
}