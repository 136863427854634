.chip {
    background-color: var(--background-action-low-blue-france);
    color: var(--text-active-red-marianne);
    border-radius: 1.5em;
    min-width: 6.25em;
    max-width: fit-content;
    padding: 0.5em;
    margin: 0.25em;
    cursor: default;
    text-align: center;
}

.chip-bouton {
    background-color: var(--background-action-low-blue-france);
    border-radius: 1.5em;
    min-width: 6.25em;
    max-width: fit-content;
    padding: 0.5em;
    margin: 0.5em;
    cursor: pointer !important;
    text-align: center;
    color: var(--text-action-high-blue-france);
    display: inline;
}

.chip--libelle {
    color:var(--text-title-grey);
    cursor: text;
}

.chip--icone {
    cursor: pointer;
}