@media (min-width: 48em) {
    .fr-footer {

        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .fr-footer .fr-container {
        max-width: none;
        width: 100%;
    }

    .fr-footer__content {
       justify-content: right;
    }
}

.numero_version {
    align-self: end;
    text-align: end;
}

.fr-footer__bottom-copy {
    width: 100%;
}

.blockFullSize {
    width: 100%;
}