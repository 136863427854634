.mainContainerReferentiel {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.leftBar {
    background-color: var(--background-default-grey-active);
}

.leftBar .cell {
    padding : 1rem;
    border-bottom: 0.12rem solid var(--background-contrast-grey-active);
}

.leftBar .cell p {
    margin : 0
}

.leftBar .active {
    background-color: var(--background-action-high-blue-france);
    color : white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftBar .iconPlus {
    border: 1px solid white;
    color : white
}

.mainContainerReferentiel .fr-modal__content {

    padding: 0 2rem;
}

.mainContainerReferentiel .modaleContent {
    border: 1px solid var(--background-action-high-blue-france);
    margin : 0;
    padding : 2rem
}

.modaleRef .modale-referentiel-toggle-input {
    margin: 0;
    display: flex;
    justify-content: center;
}

.modaleRef .modale-referentiel-toggle-input div {
    transform: translateY(-8px);
    margin : 0 1rem;
}

.modaleRef .modale-referentiel-texte-input, .modaleRef .selectInput {
    margin-bottom: 2rem;
}

.modaleRef .modale-referentiel-texte-input input{
    background-color: transparent;
}

.modaleRef .selectInput select{
    background-color: transparent;
}

.modaleRef .modale-referentiel-actif-input {
    display: flex;
    flex-direction : column;
    align-items:center
}

.tableauReferentiel > div{
    padding-top: 0;
}

.tableauReferentiel thead {
    background-color: var(--background-action-high-blue-france);
    color : white;
    text-transform: capitalize;
}

.tableauReferentiel thead tr th {
    font-size: 1rem;
}
.tableauReferentiel thead tr th:nth-child(2) {
    min-width: 75%;
}
.tableauReferentiel .referentiel-contenu-tableau{
    width: 100%;
}
.tableauReferentiel .referentiel-contenu-tableau td {
    padding : 0 0.75rem;
}
.tableauReferentiel .referentiel-contenu-tableau td:nth-child(2) {
   min-width: 75%;
}


.tableauReferentiel .tableauFiltreText input {
    background-color: var(--background-default-grey);
}

.mainContainerReferentiel .coteDroit {
    border: 0.2rem solid var(--background-action-high-blue-france);
}

.mainContainerReferentiel .coteDroit .paginationReferentiel {
    padding : 0 1rem 1rem 1rem
}

.tableauReferentiel .filtreRow {
    background-color: var(--background-contrast-grey);
}

.tableauReferentiel .texteCellule {
    cursor: pointer;
}

.tableauReferentiel .texteCellule:hover {
    background-color: var(--background-default-grey-active);
    transition-duration: 0.2s;
}


.rahcTitre {
    background-color: var(--background-action-high-blue-france);
    color : white;
}

.rahcTitre p {
    padding: 1rem;
    font-weight: bold;
}

.rahcContenu {
    height: 40;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rahcContenu > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border : 0.3rem solid var(--background-contrast-grey);
    padding : 2rem
}

.tableauReferentiel .loader-container {
    position: relative;
}

