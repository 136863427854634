body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.alerte {
    width: 25vw;
    position: fixed;
    top: 0;
    z-index: 2000; /**Pour mettre devant les modales **/
    margin: 2rem;
    right: 0;
    background-color: var(--background-default-grey) !important;
}

.d-flex {
    display: flex;
}

.bloc-bleu {
    background-color: var(--background-action-low-blue-france);
    width: 100%;
    border-bottom: 3px solid var(--background-action-high-blue-france);
    padding: 2em 2em 0 2em;
    margin: 2em 0 2em 0;
}

.texte-bleu {
    color: var(--text-title-blue-france) !important;
}

.ligne-bleu {
    background-color: var(--background-action-high-blue-france);
    color: white;
    padding: 1em !important;
}

.texte-warning {
    color: var(--background-flat-warning) !important;
}

.texte-a-droite{
    text-align: right;
}

.texte-centre .fr-table td, .texte-centre .fr-table th {
    text-align: center;
}

.texte-droite {
    text-align: right;
}

.texte-gauche{
    text-align: left;
}

.texte-erreur {
    color: var(--text-default-error);
}

.texte-justifie {
    text-align: justify;
}

.texte-petit {
    font-size: 0.8em;
}

.uppercase {
    text-transform: uppercase;
}

.text-font-size-10 {
    font-size: 10px;
}


.texte-desactive-gris {
    color: var(--text-disabled-grey);
}

.texte-active-gris {
    color: var(--text-active-grey);
}

.texte-titre-gris {
    color: var(--text-title-grey);
}

.texte-info-bleu {
    color: var(--background-flat-info);
}

.width-100 {
    width: 100%;
}

.align-item-start {
    align-items: self-start;
}

.vertical-align-middle{
    vertical-align: middle;
}
.texte-italique{
    font-style: italic;
}

.flex-space-between {
 justify-content: space-between;
}

.flex-end {
    justify-content: end;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-center {
    justify-content: center;
}

.w-100{
    width: 100%;
}

.flex-start {
    justify-content: start;
}

.flex-end {
    justify-content: end;
}

.loader-page-plein{
    width: 100%;
    height: 25vh;
}
.white-space-desactive{
    white-space: nowrap;
}

.align-self-bas {
    align-self: end;
}
.display-inline-block{
    display: inline-block;
}

.fr-toggle__label{
    overflow-wrap: normal;
}