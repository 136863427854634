.infoBulleCliquable {
    display: flex;
    margin : 0 10px;
    transition-duration: 0.3s;
}

.infoBulleCliquable .icone {
    color : var(--background-action-high-blue-france);
    transition-duration: 0.3s;
}

.infoBulleCliquable .icone :hover {
    color : var(--background-action-high-blue-france-hover);
    transition-duration: 0.3s;
}

.infoBulleCliquableContenu {
    background-color: var(--background-contrast-grey);
    color : initial;
    position: absolute;
    z-index: 1000;
    transform: translateX(1.7rem) translateY(-1rem);
    max-width: 25rem;
    padding : 0.5rem;
    border-radius: 4px;
    display: flex;
}
.infoBulleCliquableContenu p {
    font-weight: initial;
    font-size: small;
    line-height: 1rem;
    color : var(--text-default-grey);
}