.fr-btns-group>div, .fr-btns-group>li {
    width: auto !important;
}

.blue-icone__header {
    color: #2f4077;
    margin-top : 3px;
    margin-right: 5px;
}

.fr-btn[title="Rechercher"] {
    background-color: grey;
}

.fr-btn[title="Rechercher"]:hover {
    background-color: grey;
}

.recherche-active ~ .fr-btn[title="Rechercher"] {
    background-color: var(--background-action-high-blue-france);
}

/* Pour taille ecran pc */
@media (min-width: 62em) {
    .fr-header__tools-links {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: flex-end;
        margin-bottom: -13px !important;
    }

    .fr-header__tools-links .fr-btns-group {
        justify-content: flex-end;
        margin: 0 -1rem;
        margin-bottom: -13px !important;
    }

    .fr-header__tools-links a {
        padding-left: 0 !important;
        margin-left: 0 !important;

    }

    .fr-header__search, .fr-header__search .fr-container, .fr-header__search .fr-container-lg   {
        padding-right: 0 !important;
    }


    .separateur__header {
        border-right: 1px solid blue;
    }

    .pointer-conversion__header {
        cursor : pointer;
    }

    .header__item-conversion{
        margin-left: 0.5rem !important;
        color: #000091 !important;
        font-size: .875rem !important;
        margin-top: 4px;
        margin-right: 18px;

    }

    .lisere-bleu {
        border: 1px solid blue;
        padding: 1.5em;
    }

    .position-logo-conversion__header {
        margin-left: 0.5rem !important;
        margin-right: 5px;
    }

    .position-mon-compte__header {
        margin-right : 1rem !important;
    }


    .fr-header .fr-container {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .fr-header__body-row {
        width: 100% !important;
    }

    .fr-header__tools {
        padding-right: 0;
    }

    .fr-header__brand {
        margin-left: 1.5rem;
    }

    .modaleCompte {
        border: 1px solid var(--background-action-high-blue-france);
        margin : 0;
        padding : 2rem
    }

    .modaleCompte div {
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .modaleCompte div a {
        margin : 2rem 0;
    }
}