.recherche-avancee__titre, .recherche-avancee__titre .fr-label-texte {
    font-weight: bold;
    color: var(--text-title-blue-france) !important;
}

.recherche-avancee__ajouter-canon {
    z-index: 0;
}

.recherche-avancee__filtrage {
    width: 25%;
    min-width: 200px;
}

.recherche-avancee__precautions {
    width: 75%;
}

.recherche-avancee__tableau {
    width: auto;
}

.surcharge-dsfr-recherche-avance .fr-container {
    max-width: 100%;
}

#tableau-recherche-avancee table {
    display: table;
}

#tableau-recherche-avancee thead {
    width: 100%;
}

#tableau-recherche-avancee th {
    width: 12%;
}

#tableau-recherche-avancee th:nth-child(1), #tableau-recherche-avancee th:nth-child(3), #tableau-recherche-avancee th:nth-child(7), #tableau-recherche-avancee th:nth-child(9), #tableau-recherche-avancee th:nth-child(10) {
    width: 5%;
}

.recherche-avancee__img {
    object-fit: unset !important;
}

.recherche-avancee__tableau .fr-table th, .recherche-avancee__tableau .fr-table td {
    text-align: center;
}