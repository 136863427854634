.bloc-creation-de-compte {
    border-right: 1px solid black;
    padding: 0 2em 0 2em;
}

.bloc-renouvellement-mot-de-passe {
    padding: 0 2em 0 2em;
}

.information-connexion__carte{
    height: 500px;
}

.bouton-reinitialisation-mot-de-passe{
    cursor: pointer !important;
}