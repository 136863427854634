.rech-simpl-carte {
    max-width: 25rem;
}

.rech-simpl-carte--horizontal {
    width: 100%;
}

.rech-simpl-carte-calibre {
    min-width: 20rem;
    max-width: 25rem;
}

.rech-simpl-carte-marque {
    min-width: 20rem;
    max-width: 25rem;
}

.rech-simpl-carte-marque .rech-simpl-carte__presentation {
    min-height: 20rem;
}

.rech-simpl-carte__presentation {
    border: 1px solid var(--border-default-beige-gris-galet);
    box-shadow: 0 0 0.2rem var(--border-default-beige-gris-galet);
}

.rech-simpl-carte__presentation--active {
    border: 1px solid var(--background-alt-blue-ecume-active) !important;
    box-shadow: 0 0 0.2rem var(--background-alt-blue-ecume-active) !important;
}

.rech-simpl-carte__presentation:hover {
    border: 1px solid var(--background-alt-blue-ecume-hover);
    box-shadow: 0 0 0.2rem var(--background-alt-blue-ecume-hover);
}

.rech-simpl-carte .rech-simpl-carte__image {
    padding: 2rem;
    height: 15rem;
}

.rech-simpl-carte--horizontal .rech-simpl-carte__image {
    height: 10rem;
    width: auto;
    max-width: 100%;
    align-content: center;
}

.rech-simpl-carte__radio {
    padding: 2rem;
}

.rech-simpl-carte__description {
    border-left: 3px solid var(--background-action-high-blue-france);
    padding: 0 0.75rem 0 0.75rem;
    margin: 1rem 0.75rem 1rem 0.75rem;
}

.bouton-play {
    position: relative;
    background: #FFFFFF;
}
