.administration-utilisateurs__boutons-action{
    margin: 2px;
    width: 100%;
    text-align: center !important;
}
.administration-utilisateurs__bouton-action{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}