.tableauTraces .fr-table thead th  {
    font-size: 1rem !important;
}

.tableauTraces .position-fleche-tri__tableauHeaderFiltrable {

    margin-left : 1rem !important;
}

.tableauTraces .curseur-fleche-tri__tableauHeaderFiltrable {
    cursor: pointer;
}

.tableauTraces {
    padding : 2rem
}

.tableauTraces h2 {
    margin : 0
}

.tableauTraces .tableauTracesRow td {
    padding : 0.6rem 1rem
}

.tableauTraces .boutonExporter {
    justify-content: right;
    display : flex
}

.tableauTraces .longueurTableau {
    min-width : 25rem;
}

.tableauTraces .dateFiltre {
    padding: 1rem 0.1rem;
}

.tableauTraces .loader-container {
    position: relative;
    overflow: hidden;
}

.tableauTraces .loadingTd {
    padding: 0;
    height: 15vh
}