.droits_tableau {
    max-width: 100%;
}
.droits_tableau th{
    text-align: center;

}

.droits_tableau td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    text-align: center;
}

.droits_tableau td:first-child {
    text-align: left;
}

.droits_tableau__button {
    margin-left: 3rem;
}

.droits_tableau th:first-child {
    width: 15vmax;
}

.droits_tableau th:not(:first-child) {
    padding-left: 0;
    padding-right: 0;
}

.droits_tableau__alert {
    position: fixed;
    top: 0;
    z-index: 1;
    margin: 2rem;
    right: 0;
}

.droits_tableau__togglecell {
    position: relative;
}

.droits_tableau__togglecell:hover .droits_tableau__infobulle {
    visibility: visible;
    opacity: 1;
}
.droits_alignement_toogle label{
    width: 0;
}