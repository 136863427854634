.fa-fiche-arme {
    margin-right: 1rem;
    margin-left: 1rem;
}

.fiche-arme--sous-titre {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: bold;
    font-size: 1.2em;
}

.fiche-arme--supplement-sous-titre {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 1em;
    font-weight: lighter;
    font-size: 0.9em;
}

.canon--ligne-valeur {
    margin-bottom: 0.75em;
}


.fiche-arme--surcharge-curseur-checkbox-disabled * {
    cursor: default !important;
}

.fiche-arme--titre {
    color: white;
    margin: 0;
    display: inline;
    font-size: 1.5rem;
}


.tuile-arme--alignement {
    align-items: start;
}

.fiche-arme--carte-photo {
    padding: 1em;
    border-radius: 25px;
}

.fiche-arme--visible {
    padding-right: 1em;
    color: var(--text-default-success);
    font-weight: bold;
}

.fiche-arme--non-visible {
    padding-right: 1em;
    color: var(--text-default-error);
    font-weight: bold;

}

.fiche-arme--carre-photo-vide {
    display: block;
    min-width: 100%;
    min-height: 15px;
    background-color: red;
}

.fa-arme--creer-caracteristique-table {
    width: 100%;
    display: table;
    padding-bottom: 1.5em;
}


.fa-arme--creer-caracteristique-ligne {
    display: table-row;

}

.fa-arme--creer-caracteristique-cellule {
    display: table-cell;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
}


.fa-bouton-sauvegarder {
    --active: var(--background-action-high-success-active);
    --hover: var(--background-action-high-success-hover);
    background-color: var(--background-action-high-success);
}

.fa-bouton-suppression {
    --active: var(--background-action-high-red-marianne-active);
    --hover: var(--background-action-high-red-marianne-hover);
    background-color: var(--background-action-high-red-marianne);
}


.fa-groupe-bouton {
    margin-bottom: 1rem;
}

.fa-groupe-bouton button {
    margin: 0 0.75rem;
}

.fa-photo-arme {
    width: 100%;
    position: relative;
    border: dashed transparent;
    cursor: pointer;
}

.fa-photo-arme-dragging {
    border: dashed var(--background-contrast-success);
}

.fa-photo-arme--bouton {
    top: 100%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}

.fa-photo-arme--suppression {
    color: var(--red-marianne-main-472);
    top: 0;
    left: 100%;
    transform: translate(-90%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}

.fa-photo-arme--suppression:hover {
    color: var(--red-marianne-925-125-hover);
    top: 0;
    left: 100%;
    transform: translate(-90%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}


.fa-photo-groupe {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto auto;
    text-align: center;

}

.fa-photo-groupe-colonne {
    padding: 0.5em;

}

.fa-photo-groupe-colonne:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
    border-top: solid 1px var(--border-default-grey);
    border-radius: 25px 25px 0 0;
    border-left: solid 1px var(--border-default-grey);
    border-right: solid 1px var(--border-default-grey);
}

.fa-photo-groupe-colonne:nth-child(2) {
    grid-area: 1 / 3 / 2 / 7;
    border-top: solid 1px var(--border-default-grey);
    border-radius: 25px 25px 0 0;
    border-left: solid 1px var(--border-default-grey);
    border-right: solid 1px var(--border-default-grey);

}

.fa-photo-groupe-colonne:nth-child(3), .fa-photo-groupe-colonne:nth-child(5) {
    border-bottom: solid 1px var(--border-default-grey);
    border-left: solid 1px var(--border-default-grey);
    border-radius: 0 0 0 25px;
    padding: 0.5em;
}

.fa-photo-groupe-colonne:nth-child(6), .fa-photo-groupe-colonne:nth-child(7) {
    border-bottom: solid 1px var(--border-default-grey);
    padding: 0.5em;
}

.fa-photo-groupe-colonne:nth-child(4), .fa-photo-groupe-colonne:nth-child(8) {
    border-bottom: solid 1px var(--border-default-grey);
    border-right: solid 1px var(--border-default-grey);
    border-radius: 0 0 25px 0;
    padding: 0.5em;
}

.fa-DND-conteneur {
    display: flex;
    overflow: visible;
    width: 100%;
}

.fa-DND-conteneur-mouvement {
    display: flex;
    overflow: visible;
    width: 100%;
    background-color: var(--background-alt-blue-france);
}

.fa-surcharge-container-DSFR {
    max-width: 100%;

}

#fa-segment-loader .segment-loader {
    width: 100% !important;
    margin: 0;
}

.modale-traitement-demande-textearea textarea {
    min-height: 40vh;
    resize: vertical;
    height: 100%;
}

.modale-traitement-demande-textearea div {
    width: 100%;
    text-align: right;
    font-style: italic;
}

/*Surcharge DSFR pour version buildé*/
.fr-input-group--error::before {
    background-image: none;
}

.fa-checkbox {
    height: 1.5rem;
    width: 1.5rem;
    accent-color: var(--background-action-high-blue-ecume);
}

#fa-modale-traiter-demande .fr-col-md-6 {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
}
