.importMasse .zoneTitre {
    display: flex;
}

.importMasse .zoneTitre h2 {
    margin-bottom: 0;
    margin-top: 1rem;
}

.importMasse .zoneTitre p {
    font-weight: bolder;
}

.importMasse .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top : 4rem
}

.importMasse .content button {
    margin-bottom: 3rem;
    margin-top : 1rem
}

.importMasse .content p {
    font-weight: bolder;
}

.importMasse .content .description {
    color: gray;
    font-size: small;
}

.importMasse .content a {
    color: var(--text-title-blue-france);
}

.importMasse .boutonRetour {
    margin-bottom: 3rem;
}

.divSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divSwitch > * {
    margin: 0 1rem;
}

.divSwitch p {
    font-size: 1.3rem;
}