.rech-simpl-carte__gif {
    position: relative;
}

.rech-simpl-carte__gif:hover {
    position: relative;
}

.play-button {
    display: flex;
    justify-content: flex-end;
    height: 1.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    filter: invert(0.75);
    margin-right: 1rem;
}

.recherche-modale-gif {
    width: 90%;
    height: 100vh;
    max-width: 85% !important;
    margin-right: auto;
    margin-left: auto;
}
.recherche-modale-gif .fr-col-md-8{
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
}
.recherche-modale-gif .fr-modal--opened {
    height: 100vh;

}

.recherche-modale-gif .fr-modal::before {
    flex: unset;
    height: 100vh;
    vertical-align: center;

}

.recherche-modale-gif .fr-container {
    max-width: none;
    margin: inherit;
    height: 100vh;

}

.recherche-modale-gif .fr-modal__body {
    overflow: unset;
    height: 100vh;
    max-height: 100vh !important;
}


.recherche-modale-gif .segment-loader {
    background-color: yellow;
    text-align: center;
}

.recherche-modale-gif img {
    height: 90vh;
    width: auto;
}