.caseLongue .dateFiltre {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}

.caseLongue .fr-container {
    padding-left: 0;
    padding-right: 0;
}

.infosHeader {
    width: 8.5rem
}

.actionHeader {
    width: 5rem
}

.admin-arme__bouton-supprimer {
    color: var(--text-action-high-red-marianne)
}

