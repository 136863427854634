.autocomplete-control {
    background-color: var(--background-contrast-grey);
    border-radius: 0.25rem 0.25rem 0 0;
    font-size: 1rem;
    padding: 5px;
    line-height: 1.5rem;
    border-bottom: 2px solid var(--border-plain-grey);
    overflow: hidden;
    max-height: 2.5rem;
    height: 2.5rem;
}

.select-control {
    background-color: var(--background-contrast-grey);
    border-radius: 0.25rem 0.25rem 0 0;
    font-size: 1rem;
    padding: 5px;
    line-height: 1.5rem;
    border-bottom: 2px solid var(--border-plain-grey);

}


.autocomplete-erreur {
    border-bottom: 2px solid var(--text-default-error);
}

.autocomplete-container:hover {
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0 0 0 2px #0a76f6;
    cursor: pointer;
}

.autocomplete-container {
    text-align: left;
    cursor: pointer;

}


.autocomplete-valueContainer {
    margin-left: 5px;
    font-weight: normal;
    color: var(--text-title-grey);
    max-height: 1.5rem;
}

.select-valueContainer {
    margin-left: 5px;
    font-weight: normal;
    color: var(--text-title-grey);
}

.select-multiValue {
    background-color: var(--background-contrast-overlap-grey-active);
}

.autocomplete-indicatorsContainer {
    margin-right: 5px;
    color: var(--text-title-grey);

}

.autocomplete-menu {
    background-color: var(--background-contrast-grey);
    font-weight: normal;
    color: var(--text-title-grey);
    z-index: 999 !important;
    border: 1px solid var(--border-plain-grey);
}

.autocomplete-option {
    text-align: left;
    min-height: 1.2em;
    padding: 0 1em;

}

.autocomplete-option:hover {
    background-color: var(--background-contrast-grey-hover);
    color: var(--text-title-grey);
}

