.select-type-canon {
    height: 2.2rem;
    width: 10.4rem;
    border: 1px solid var(--background-action-high-blue-france);
    cursor: pointer;
    user-select: none;
}

.select-type-canon--actif {
    box-shadow: 0 0 0.4rem var(--background-alt-blue-ecume-active) !important;
}

.select-type-canon:hover {
    height: 2.2rem;
    width: 10.4rem;
    border: 1px solid var(--background-action-high-blue-france);
}

.select-type-canon__options {
    width: 10.4rem;
    z-index: 1;
    border: 1px solid var(--background-action-high-blue-france);
}

.select-type-canon__option--nom {
    margin-left: 0.2rem;
    margin-top: 0.25rem;
}

.select-type-canon__option--element-gauche {
    margin: 0.2rem 0.2rem 0.2rem 0.2rem;
}

.select-type-canon__option--image {
    height: 2rem;
}

.select-type-canon__dropdown-icone {
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}