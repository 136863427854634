.App {
  text-align: center;
}


.uppercase {
  text-transform: uppercase;
}

.texte-bleu {
  color: var(--text-title-blue-france) !important;
}

.texte-centre {
  text-align: center;
}

.texte-petit {
  font-size: 0.8em;
}

.app_marge{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.max-content{
  width: max-content;
}

.select-placeholder {
  color: var(--text-mention-grey);
  font-style: italic;
  opacity: 1
}
.tr-1px{
  height: 1px;
}
.h-100{
  height: 100%;
}