.modale-type-canon-exemple {
    border: 2px solid var(--background-contrast-grey);
    padding: 0.5rem;
    margin: 0.5rem;
}

.modale-type-canon-titre {
    border-bottom: 2px solid var(--background-contrast-grey);
    padding-bottom: 0.25rem;
}

.modale-type-canon-image {
    border: 2px solid var(--background-contrast-grey);
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    margin: 0.75rem 0.25rem 0.25rem 0.25rem;
}

.modale-type-canon-taille-image {
    width: 3.5rem;
    height: 3.5rem;
}

.modale-type-canon-lisse-raye {
    width: 100%;
}
