tbody tr:hover {
    background-color: var(--background-action-low-blue-france) !important;
    cursor: pointer;
}

.bloc-bordure-bleu {
    width: 100%;
    min-height: 7em;
    border-left: 1px solid var(--text-disabled-grey);
    border-right: 1px solid var(--text-disabled-grey);
    border-top: 1px solid var(--text-disabled-grey);
    border-bottom: 5px solid var(--background-action-high-blue-france);
    padding: 2em 2em 0 2em;
    margin: 0.5em 0.5em;
}

.info-titre {
    font-weight: bold !important;
    font-size: 1.2rem !important;
    margin-bottom: 0.6rem !important;
    color: var(--text-disabled-grey) !important;
}

.bloc-canon {
    display: flex;
    flex-direction: column;
}

.info-titre-canon {
    color: black;
    font-weight: bold;
}

.info-libelle {
    color: var(--text-default-grey);
    font-size: 0.9rem;
}

.info-canon {
    height: 25%;
}

.modal-recap-arme-surcharge-DSFR .fr-container {
    max-width: 80%;
}


.modal-recap-arme-surcharge-DSFR .fr-col-md-8 {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;

}

.modal-recap-arme-surcharge-DSFR .segment-loader {
    max-width: none;
    min-width: 80vmax;
}

.modal-recap-arme-surcharge-DSFR .fr-modal::before {
    flex: unset;
    vertical-align: center;
    margin-top: 2vh;
}


.modal-recap-arme-surcharge-DSFR .fr-modal__body {
    overflow-y: auto;
    max-height: 96vh !important;
    overflow-x: unset;
}

.modal-recap-arme-surcharge-DSFR .fr-modal__content {
    margin-bottom: 0;
}


.modal-recap-arme-surcharge-DSFR .fr-modal__footer {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 1rem;
}