.monCompte {
    margin-bottom: 2rem;
}

.monCompte .infoMonCompte {

    padding: 2rem;
}

.monCompte .infoMonCompte input {
    background-color: transparent;
    box-shadow : black;
}

.monCompte .infoMonCompte label {
    color : var(--text-title-grey);
}

.monCompte .infoMonCompte .motDePasseNote {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .75rem;
    line-height: 1.25rem;
    margin-top: 0.7rem;
    margin-bottom: 0;
    color: var(--text-title-grey);
}

.monCompte .bloc-bleu {
    padding-bottom: 1rem;
    min-height: 25rem;
}

.monCompte .bloc-bleu > div > div {
    min-height: 18rem;
}

.monCompte .bloc-bleu .titre-souligne {
    border-bottom: 1px solid var(--text-action-high-blue-france);
    color : var(--text-action-high-blue-france)
}

.monCompte .bloc-bleu .details {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.monCompte .bloc-bleu .details p {
    font-weight: bolder;
}

.monCompte .bloc-bleu .details ul {
    line-height: 1.3rem;
}

.monCompte .bloc-bleu input {
    background-color: var(--background-default-grey)
}

.monCompte .bloc-bleu label {
    color: var(--text-action-high-blue-france)
}

.monCompte .bloc-bleu .boutons {
    display: flex;
    align-items: flex-end;
    justify-content:center;
}

.monCompte .bloc-bleu .boutons button {
    margin: 0 0.3rem;
}